import getListViewStore from '../store/Store';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';

export default function isFolderPausedInbox(folderId: string): boolean {
    return !!(
        getListViewStore().inboxPausedDateTime &&
        folderId &&
        folderIdToName(folderId) === 'inbox'
    );
}
