import type { ApplyConversationActionContext } from 'owa-mail-triage-action-utils';

import getTableConversationRelation from './getTableConversationRelation';
import getFolderLastSyncTime from './getFolderLastSyncTime';
import getListViewStore from '../store/Store';
import { getISOString } from 'owa-datetime';

/**
 * Gets contextual information for ApplyConversationAction for a given conversation
 */
export default function getApplyConversationActionContext(
    conversationRowKey: string,
    tableViewId: string
): ApplyConversationActionContext | undefined {
    const tableConversationRelation = getTableConversationRelation(conversationRowKey, tableViewId);
    if (!tableConversationRelation) {
        return undefined;
    }

    let conversationLastSyncTimeStamp = null;

    const folderId = getListViewStore().tableViews.get(tableViewId)?.tableQuery.folderId;
    const lastSyncTime = folderId ? getFolderLastSyncTime(folderId) : null;
    if (lastSyncTime) {
        conversationLastSyncTimeStamp = getISOString(lastSyncTime);
    } else {
        conversationLastSyncTimeStamp = new Date(
            tableConversationRelation.lastModifiedTimeStamp
        ).toISOString();
    }

    return <ApplyConversationActionContext>{
        //TODO: use ClientItemId type in ApplyConversationActionContext: 23338
        // Work Item 27629: Remove workaround for timezone issue (sending requests in UTC time zone)
        conversationId: tableConversationRelation.id,
        conversationLastSyncTimeStamp,
    };
}
