import getListViewStore from '../store/Store';
import { getRowIdString } from '../selectors/mailRowDataPropertyGetter';

/**
 * Returns a collection of the rowIdStrings for the given rowKeys
 * @param rowKeys rowKeys to get rowIds for
 * @param tableView tableView to loop up
 */
export default function getRowIdsFromRowKeys(rowKeys: string[], tableViewId: string): string[] {
    const tableView = getListViewStore().tableViews.get(tableViewId);

    // Convert and remove null cases
    // Strict mode was enabled in this package. See aka.ms/client-web-strict-mode for details.
    // -> Error TS2345 (16,65): Argument of type 'TableView | undefined' is not assignable to parameter of type 'TableView'.
    // @ts-expect-error
    const rowIdStrings = rowKeys.map(key => getRowIdString(key, tableView)).filter(key => key);

    // In case of multi-value sorts there can be two rows with same conversation/item
    // Hence we have to dedup the ids
    return rowIdStrings.filter((item, pos) => rowIdStrings.indexOf(item) == pos);
}
