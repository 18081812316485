import { mutatorAction } from 'satcheljs';
import getStore from '../store/Store';
import { isFeatureEnabled } from 'owa-feature-flags';

export const updateShouldShowSelectedRowInRp = mutatorAction(
    'updateShouldShowSelectedRowInRp',
    (shouldShowSelectedRowInRp: boolean) => {
        // Only update the store value if the feature flag is enabled. If disabled,
        // default value of store value will retain control behavior.
        if (isFeatureEnabled('tri-context-menu-no-rp')) {
            getStore().shouldShowSelectedRowInRp = shouldShowSelectedRowInRp;
        }
    }
);
