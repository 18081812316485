import { type SortColumn } from '../store/schema/SortBy';
import { type TableQueryType } from '../store/schema/TableQuery';

import type {
    SortColumn as GqlSortColumn,
    ViewFilter as GqlViewFilter,
    FocusedViewFilter as GqlFocusedViewFilter,
    ReactListViewType as GqlReactListViewType,
    TableQueryType as GqlTableQueryType,
    PagingInfoInput as GqlPagingInfo,
} from 'owa-graph-schema';
import { assertNever } from 'owa-assert';
import type ViewFilter from 'owa-service/lib/contract/ViewFilter';
import type FocusedViewFilter from 'owa-service/lib/contract/FocusedViewFilter';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';

export function getGQLPagingInfoForQueryRows(
    numberOfRowsToFetch: number,
    isConversationView: boolean,
    instanceKey?: string
): GqlPagingInfo {
    const pageInfo: GqlPagingInfo = {
        numberOfRows: numberOfRowsToFetch,
    };

    if (instanceKey) {
        pageInfo.restriction = {
            restrictionComparisonType: 'isEqual',
            restrictionProperty: isConversationView ? 'ConversationInstanceKey' : 'InstanceKey',
            restrictionValue: instanceKey,
        };
    }

    return pageInfo;
}

export function getGqlSortColumnFromOwsSortColumn(sortColumn: SortColumn): GqlSortColumn {
    switch (sortColumn) {
        case 1:
            return 'Date';
        case 3:
            return 'From';
        case 5:
            return 'Importance';
        case 7:
            return 'Size';
        case 8:
            return 'Subject';
        case 14:
            return 'Category';
        case 9:
            return 'To';
        case 15:
            return 'ReturnTime';
        case 16:
            return 'Priority';
        default:
            return assertNever(sortColumn);
    }
}

export function getGqlViewFilterFromOwsViewFilter(viewFilter: ViewFilter): GqlViewFilter {
    switch (viewFilter) {
        case 'All':
        case 'Flagged':
        case 'HasAttachment':
        case 'ToOrCcMe':
        case 'Unread':
        case 'UserCategory':
        case 'Focused':
        case 'Mentioned':
        case 'HasFile':
        case 'HasCalendarInvite':
            return viewFilter;
        default:
            assertNever(viewFilter as never);
    }
}

export function getGqlFocusedViewFilterFromOwsFocusedViewFilter(
    focusedViewFilter: FocusedViewFilter
): GqlFocusedViewFilter {
    switch (focusedViewFilter) {
        case -1:
            return 'None';
        case 0:
            return 'Focused';
        case 1:
            return 'Other';
        default:
            assertNever(focusedViewFilter);
    }
}

export function getGqlReactListViewTypeFromOwsReactListViewType(
    reactListViewType: ReactListViewType
): GqlReactListViewType {
    switch (reactListViewType) {
        case 0:
            return 'Conversation';
        case 1:
            return 'Message';
        case 2:
            return 'CalendarItems';
        default:
            assertNever(reactListViewType);
    }
}

export function getGqlTableQueryTypeFromOwaTableQueryType(type: TableQueryType): GqlTableQueryType {
    switch (type) {
        case 0:
            return 'Folder';
        case 1:
            return 'Search';
        case 2:
            return 'Group';
        case 3:
            return 'GroupFolder';
        default:
            assertNever(type);
    }
}
