import type DropViewState from 'owa-dnd/lib/store/schema/DropViewState';
import getListViewState from '../selectors/getListViewState';
import createFocusedOtherDropViewState from '../actions/createFocusedOtherDropViewState';

export default function getFocusedOtherDropViewState(): DropViewState {
    const listViewState = getListViewState();
    if (!listViewState.focusedOtherDropViewState) {
        createFocusedOtherDropViewState();
    }

    /* eslint-disable-next-line @typescript-eslint/no-non-null-assertion  -- (https://aka.ms/OWALintWiki)
     * Non-null assertions are dangerous, as they can hide bugs from strictness checks. Please remove this usage or replace this line with a justification.
     *	> Forbidden non-null assertion. */
    return listViewState.focusedOtherDropViewState!;
}
