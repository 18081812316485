import type MailFolderTableQuery from '../../store/schema/MailFolderTableQuery';
import type SortBy from '../../store/schema/SortBy';
import { type SortColumn } from '../../store/schema/SortBy';
import type TableQuery from '../../store/schema/TableQuery';
import { type TableQueryType } from '../../store/schema/TableQuery';
import type ReactListViewType from 'owa-service/lib/contract/ReactListViewType';
import type SortDirection from 'owa-service/lib/contract/SortDirection';
import folderIdToName from 'owa-session-store/lib/utils/folderIdToName';
import { isShadowMailboxUser } from 'owa-session-store';
import type { MailboxInfo } from 'owa-client-types';
import { isFeatureEnabled } from 'owa-feature-flags';

export const DESCENDING_SORT_DIR: SortDirection = 'Descending';
export const ASCENDING_SORT_DIR: SortDirection = 'Ascending';
export const DEFAULT_SORT_COLUMN: SortColumn = 1;

/**
 * Gets the SortBy that supports Pin
 */
export function getSortBySupportingPin(): SortBy {
    return {
        sortColumn: 1,
        sortDirection: DESCENDING_SORT_DIR,
    };
}

/**
 * Gets the list of supported sort columns
 * @param folderId for which to return the sort columns
 */
export function getSupportedSortColumns(folderId: string, mailboxInfo: MailboxInfo) {
    const folderName = folderIdToName(folderId);
    const isSentOrDraftsFolder = 'sentitems' == folderName || 'drafts' == folderName;

    let supportedSortColumns: SortColumn[] = [];
    if (isSentOrDraftsFolder) {
        supportedSortColumns = [1, 9, 7];
    } else {
        supportedSortColumns = [1, 3, 7, 5, 8];
    }

    if (!isShadowMailboxUser(mailboxInfo)) {
        supportedSortColumns.splice(isSentOrDraftsFolder ? 2 : 1, 0, 14);
    }

    if (folderName === 'scheduled') {
        supportedSortColumns.splice(1, 0, 15);
    }

    if (isFeatureEnabled('mon-copilot-inbox')) {
        supportedSortColumns.splice(0, 0, 16);
    }

    return supportedSortColumns;
}

/**
 * Gets the default sortDirection for the given sortColumn
 * @param sortColumn sortColumn for which to get the default sort direction
 * @returns the sortDirection
 */
export function getDefaultSortDirectionForSortColumn(sortColumn: SortColumn): SortDirection {
    switch (sortColumn) {
        case 16:
        case 1:
        case 7:
        case 5:
        case 14:
            return DESCENDING_SORT_DIR;

        case 3:
        case 9:
        case 8:
        case 15:
            return ASCENDING_SORT_DIR;

        default:
            throw new Error('getDefaultSortDirectionForSortColumn: sortColumn not supported');
    }
}

/**
 * Gets the default sortBy property
 * @returns returns the default sortBy property
 */
export function getDefaultSortBy(): SortBy {
    return {
        sortColumn: DEFAULT_SORT_COLUMN,
        sortDirection: getDefaultSortDirectionForSortColumn(DEFAULT_SORT_COLUMN),
    };
}

/**
 * Gets a flag indicating whether the given tableQuery is multi-value sorted
 * @param tableQuery tableQuery to check if multi-value sorted
 * @return flag indicating whether the given tableQuery is multi-value sorted
 */
export function isTableMultiValueSort(tableQuery: TableQuery): boolean {
    const tableSortColumn =
        tableQuery.type == 0 && (tableQuery as MailFolderTableQuery).sortBy.sortColumn;
    return tableSortColumn && tableQuery.listViewType == 0 && tableSortColumn == 3;
}
