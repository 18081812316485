import { mutatorAction } from 'satcheljs';
import getListViewState from '../selectors/getListViewState';
import createDropViewState from 'owa-dnd/lib/utils/createDropViewState';

export default mutatorAction(
    'createFocusedOtherDropViewState',
    function createFocusedOtherDropViewState() {
        getListViewState().focusedOtherDropViewState = createDropViewState();
    }
);
